import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useDataContext} from "../../services/data.service";

const NavMenuContent = ({ closeMenu, buttonEl }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const menuEl = useRef(null);

    const isDatasetsActive = pathname.startsWith("/datasets");
    const isProgressActive = pathname.startsWith("/statistics")

    const linkClasses = "pl-5 py-2.5 text-left text-gray-500 hover:bg-blue-500 hover:text-white";
    const activeClasses = "pl-5 py-2.5 text-left font-semibold text-gray-700 shadow-inner bg-blue-100 hover:bg-blue-500 hover:text-white";

    useEffect(() => {
        if (menuEl && menuEl.current && buttonEl && buttonEl.current) {
            const handleClickOutside = event => {
                if (!menuEl.current.contains(event.target) && !buttonEl.current.contains(event.target)) {
                    closeMenu();
                }
            };

            document.addEventListener("mousedown", handleClickOutside);

            return () => document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [menuEl, buttonEl])

    return (
        <div className="absolute top-12 w-64 shadow-lg pb-4 left-0 bg-white text-slate-900 overflow-hidden" style={{zIndex: 999}} ref={menuEl}>
            <h3 className="text-3xl px-4 py-3">Navigation</h3>
            <div className="flex flex-col">
                <button onClick={() => {
                    navigate("/datasets");
                    closeMenu();
                }} className={isDatasetsActive ? activeClasses : linkClasses}>Datasets</button>
                {/*<button onClick={() => {*/}
                {/*    navigate("/statistics");*/}
                {/*    closeMenu();*/}
                {/*}}*/}
                {/*        className={isProgressActive ? activeClasses : linkClasses}>Progress & Statistics</button>*/}
                {/*<Link className="pl-5 py-2.5 font-semibold text-gray-500 hover:bg-blue-500 hover:text-white">User Management</Link>*/}
            </div>
        </div>
    )
}

const NavMenu = () => {
    const [menuOpen, rawSetMenuOpen] = useState(false);

    const {setIsMenuOpen} = useDataContext();

    const setMenuOpen = val => {
        if (typeof val !== "boolean") return;

        setIsMenuOpen(val);
        rawSetMenuOpen(val);
    }

    const buttonEl = useRef(null);

    return (
        <div>
            <button
                ref={buttonEl}
                className={
                    `text-lg
                    font-semibold 
                    uppercase 
                    tracking-wider 
                    h-12
                    w-12
                    flex
                    place-items-center
                    justify-center
                    bg-transparent text-slate-100
                    hover:bg-slate-800 hover:text-white
                    `
                }
                onClick={() => setMenuOpen(!menuOpen)}
            >{ menuOpen ?
                <FontAwesomeIcon icon={faXmark} /> :
                <FontAwesomeIcon icon={faBars} />}</button>
            { menuOpen ? <NavMenuContent buttonEl={buttonEl} closeMenu={() => setMenuOpen(false)} /> : null }
        </div>
    )
};

export default NavMenu;