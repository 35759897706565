import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';



import {Toaster} from "react-hot-toast";
import AuthProvider, {AuthContext} from "./services/auth.service";
import DataProvider from "./services/data.service";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/Home";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import DatasetsPage from "./pages/Datasets";
import AnnotatorPage from "./pages/Annotator";
import ONNXProvider from "./services/onnx.service";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <AuthProvider>
            <DataProvider>
                <BrowserRouter>
                    <Layout>
                        <AuthContext.Consumer>
                            {auth =>
                                <Routes>
                                    <Route index element={<HomePage/>}/>
                                        <Route element={<PrivateRoute isAllowed={!!auth.user}/>}>
                                            <Route path="/datasets" element={<DatasetsPage/>} />
                                            <Route path="/annotator" element={<AnnotatorPage/>}/>
                                        </Route>
                                </Routes>
                            }
                        </AuthContext.Consumer>
                    </Layout>
                </BrowserRouter>
            </DataProvider>
        </AuthProvider>
        <Toaster/>
    </>
);

