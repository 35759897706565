import {FullScreen} from "react-full-screen"
import React, {useCallback, useRef, useState, useEffect} from "react"
import {makeStyles, styled} from "@material-ui/core/styles"
import {InferenceSession} from "onnxruntime-web";
import ClassSelectionMenu from "../ClassSelectionMenu"
import DebugBox from "../DebugSidebarBox"
import HistorySidebarBox from "../HistorySidebarBox"
import ImageCanvas from "../ImageCanvas"
import KeyframeTimeline from "../KeyframeTimeline"
import KeyframesSelector from "../KeyframesSelectorSidebarBox"
import RegionSelector from "../RegionSelectorSidebarBox"
import SettingsDialog from "../SettingsDialog"
import TagsSidebarBox from "../TagsSidebarBox"
import TaskDescription from "../TaskDescriptionSidebarBox"
import Workspace from "react-material-workspace-layout/Workspace"
import classnames from "classnames"
import getActiveImage from "../Annotator/reducers/get-active-image"
import getHotkeyHelpText from "../utils/get-hotkey-help-text"
import iconDictionary from "./icon-dictionary"
import styles from "./styles"
import {useDispatchHotkeyHandlers} from "../ShortcutsManager"
import useEventCallback from "use-event-callback"
import useImpliedVideoRegions from "./use-implied-video-regions"
import useKey from "use-key-hook"
import {useSettings} from "../SettingsProvider"
import {withHotKeys} from "react-hotkeys"
import {useDataContext} from "../../../services/data.service";
import {useONNXContext} from "../../../services/onnx.service";

const emptyArr = []
const useStyles = makeStyles(styles)

const HotkeyDiv = withHotKeys(({hotKeys, children, divRef, ...props}) => (<div {...{...hotKeys, ...props}} ref={divRef}>
        {children}
    </div>))

const FullScreenContainer = styled("div")({
    width: "100%", height: "100%", "& .fullscreen": {
        width: "100%", height: "100%",
    },
})

export const MainLayout = ({
                               state,
                               dispatch,
                               alwaysShowNextButton = false,
                               alwaysShowPrevButton = false,
                               RegionEditLabel,
                               onRegionClassAdded,
                               onPolygonAdded,
                               hideHeader,
                               hideHeaderText,
                               hideNext = false,
                               hidePrev = false,
                               hideClone = false,
                               hideSettings = false,
                               hideFullScreen = false,
                               hideSave = false,
                               minimalMode = false
                           }) => {
    const classes = useStyles()
    const settings = useSettings()

    const {fullScreenHandle, enterFullScreen, exitFullScreen} = useDataContext();
    const { loadEmbeddingsFromFile, maskImg, tensor } = useONNXContext();

    const memoizedActionFns = useRef({})
    const action = (type, ...params) => {
        const fnKey = `${type}(${params.join(",")})`

        if (memoizedActionFns.current[fnKey]) return memoizedActionFns.current[fnKey]

        const fn = (...args) => params.length > 0 ? dispatch(({
            type, ...params.reduce((acc, p, i) => ((acc[p] = args[i]), acc), {}),
        })) : dispatch({type, ...args[0]})
        memoizedActionFns.current[fnKey] = fn
        return fn
    }

    const {currentImageIndex, activeImage} = getActiveImage(state)
    let nextImage
    if (currentImageIndex !== null) {
        nextImage = state.images[currentImageIndex + 1]
    }

    useKey(() => dispatch({type: "CANCEL"}), {
        detectKeys: [27],
    })

    const isAVideoFrame = activeImage && activeImage.frameTime !== undefined
    const innerContainerRef = useRef()
    const hotkeyHandlers = useDispatchHotkeyHandlers({dispatch})

    let impliedVideoRegions = useImpliedVideoRegions(state)

    const refocusOnMouseEvent = useCallback((e) => {
        if (!innerContainerRef.current) return
        if (innerContainerRef.current.contains(document.activeElement)) return
        if (innerContainerRef.current.contains(e.target)) {
            innerContainerRef.current.focus()
            e.target.focus()
        }
    }, [])

    const canvas = (<ImageCanvas
            {...settings}
            showCrosshairs={settings.showCrosshairs && !["select", "pan", "zoom"].includes(state.selectedTool)}
            key={state.selectedImage}
            showMask={state.showMask}
            fullImageSegmentationMode={state.fullImageSegmentationMode}
            autoSegmentationOptions={state.autoSegmentationOptions}
            showTags={!minimalMode && state.showTags}
            allowedArea={state.allowedArea}
            modifyingAllowedArea={state.selectedTool === "modify-allowed-area"}
            regionClsList={state.regionClsList}
            regionTagList={state.regionTagList}
            regions={state.annotationType === "image" ? activeImage.regions || [] : impliedVideoRegions}
            realSize={activeImage ? activeImage.realSize : undefined}
            videoPlaying={state.videoPlaying}
            imageSrc={state.annotationType === "image" ? activeImage.src : null}
            videoSrc={state.annotationType === "video" ? state.videoSrc : null}
            pointDistancePrecision={state.pointDistancePrecision}
            createWithPrimary={state.selectedTool.includes("create")}
            dragWithPrimary={state.selectedTool === "pan"}
            zoomWithPrimary={state.selectedTool === "zoom"}
            showPointDistances={state.showPointDistances}
            videoTime={state.annotationType === "image" ? state.selectedImageFrameTime : state.currentVideoTime}
            keypointDefinitions={state.keypointDefinitions}
            onMouseMove={action("MOUSE_MOVE")}
            onMouseDown={action("MOUSE_DOWN")}
            onMouseUp={action("MOUSE_UP")}
            onChangeRegion={action("CHANGE_REGION", "region")}
            onBeginRegionEdit={action("OPEN_REGION_EDITOR", "region")}
            onCloseRegionEdit={action("CLOSE_REGION_EDITOR", "region")}
            onDeleteRegion={action("DELETE_REGION", "region")}
            onBeginBoxTransform={action("BEGIN_BOX_TRANSFORM", "box", "directions")}
            onBeginMovePolygonPoint={action("BEGIN_MOVE_POLYGON_POINT", "polygon", "pointIndex")}
            onBeginMoveKeypoint={action("BEGIN_MOVE_KEYPOINT", "region", "keypointId")}
            onAddPolygonPoint={action("ADD_POLYGON_POINT", "polygon", "point", "pointIndex")}
            onSelectRegion={action("SELECT_REGION", "region")}
            onBeginMovePoint={action("BEGIN_MOVE_POINT", "point")}
            onImageLoaded={action("IMAGE_LOADED", "image")}
            RegionEditLabel={RegionEditLabel}
            onImageOrVideoLoaded={action("IMAGE_OR_VIDEO_LOADED", "metadata")}
            onChangeVideoTime={action("CHANGE_VIDEO_TIME", "newTime")}
            onChangeVideoPlaying={action("CHANGE_VIDEO_PLAYING", "isPlaying")}
            onRegionClassAdded={onRegionClassAdded}
            allowComments={state.allowComments}
            minimalMode={minimalMode}
            maskOverlayImage={maskImg}
            samPoints={state.samPoints}
            resetSamPoints={action("RESET_SAM")}
            toggleSamPoint={action("TOGGLE_SAM_POINT", "pointIndex")}
            bakeSamPoints={action("BAKE_SAM", "detailLevel")}
        />)

    const onClickIconSidebarItem = useEventCallback((item) => {
        dispatch({type: "SELECT_TOOL", selectedTool: item.name})
    })

    const onClickHeaderItem = useEventCallback((item) => {
        if (item.name === "Fullscreen") {
            enterFullScreen();
        } else if (item.name === "Window") {
            exitFullScreen();
        }
        dispatch({type: "HEADER_BUTTON_CLICKED", buttonName: item.name})
    })

    const debugModeOn = Boolean(window.localStorage.$ANNOTATE_DEBUG_MODE && state)
    const nextImageHasRegions = !nextImage || (nextImage.regions && nextImage.regions.length > 0)

    const hasEmbeddings = (activeImage.attachments && activeImage.attachments.length > 0
        && activeImage.attachments.some(attachment => attachment.type === 1))

    useEffect(() => {
        if (!loadEmbeddingsFromFile || !activeImage || !activeImage.attachments) return;

        if (hasEmbeddings && tensor === null) {
            loadEmbeddingsFromFile(activeImage.attachments[0].url);
        }
    }, [tensor, loadEmbeddingsFromFile, hasEmbeddings, activeImage])

    if (!fullScreenHandle) return null;

    return (<FullScreenContainer>
            <FullScreen
                handle={fullScreenHandle}
                onChange={(open) => {
                    if (!open) {
                        exitFullScreen();
                        action("HEADER_BUTTON_CLICKED", "buttonName")("Window");
                    }
                }}
            >
                <HotkeyDiv
                    tabIndex={-1}
                    divRef={innerContainerRef}
                    onMouseDown={refocusOnMouseEvent}
                    onMouseOver={refocusOnMouseEvent}
                    allowChanges
                    handlers={hotkeyHandlers}
                    className={classnames(classes.container, state.fullScreen && "Fullscreen")}
                >
                    <Workspace
                        allowFullscreen
                        iconDictionary={iconDictionary}
                        hideHeader={hideHeader}
                        hideHeaderText={hideHeaderText}
                        headerLeftSide={[state.annotationType === "video" ? (<KeyframeTimeline
                                currentTime={state.currentVideoTime}
                                duration={state.videoDuration}
                                onChangeCurrentTime={action("CHANGE_VIDEO_TIME", "newTime")}
                                keyframes={state.keyframes}
                            />) : activeImage ? (
                            <div className={classes.headerTitle}>{activeImage.name}</div>) : null,].filter(Boolean)}
                        headerItems={[!hidePrev && {name: "Prev"}, !hideNext && {name: "Next"}, state.annotationType !== "video" ? null : !state.videoPlaying ? {name: "Play"} : {name: "Pause"}, !hideClone && !nextImageHasRegions && activeImage.regions && {name: "Clone"}, !hideSettings && {name: "Settings"}, !hideFullScreen && (state.fullScreen ? {name: "Window"} : {name: "Fullscreen"}), !hideSave && {name: "Save"},].filter(Boolean)}
                        onClickHeaderItem={onClickHeaderItem}
                        onClickIconSidebarItem={onClickIconSidebarItem}
                        selectedTools={[state.selectedTool, state.showTags && "show-tags", state.showMask && "show-mask",].filter(Boolean)}

                        iconSidebarItems={[!minimalMode && {
                            name: "select",
                            helperText: "Select" + getHotkeyHelpText("select_tool"),
                            alwaysShowing: true,
                        }, {
                            name: "pan",
                            helperText: "Drag/Pan (right or middle click)" + getHotkeyHelpText("pan_tool"),
                            alwaysShowing: true,
                        }, {
                            name: "zoom",
                            helperText: "Zoom In/Out (scroll)" + getHotkeyHelpText("zoom_tool"),
                            alwaysShowing: true,
                        }, !minimalMode && {
                            name: "show-tags", helperText: "Show / Hide Tags", alwaysShowing: true,
                        }, !minimalMode && hasEmbeddings && tensor && {
                            name: "create-sam-points", helperText: "Automatically Segment Points",
                        }, {
                            name: "create-point", helperText: "Add Point" + getHotkeyHelpText("create_point"),
                        }, !minimalMode && {
                            name: "create-box",
                            helperText: "Add Bounding Box" + getHotkeyHelpText("create_bounding_box"),
                        }, !minimalMode && {
                            name: "create-polygon", helperText: "Add Polygon" + getHotkeyHelpText("create_polygon"),
                        }, !minimalMode && {
                            name: "create-line", helperText: "Add Line",
                        }, !minimalMode && {
                            name: "create-expanding-line", helperText: "Add Expanding Line",
                        }, !minimalMode && {
                            name: "create-keypoints", helperText: "Add Keypoints (Pose)",
                        }, state.fullImageSegmentationMode && {
                            name: "show-mask", alwaysShowing: true, helperText: "Show / Hide Mask",
                        }, {
                            name: "modify-allowed-area", helperText: "Modify Allowed Area",
                        },]
                            .filter(Boolean)
                            .filter((a) => a.alwaysShowing || state.enabledTools.includes(a.name))}
                        rightSidebarItems={!minimalMode ? [debugModeOn && (
                            <DebugBox state={debugModeOn} lastAction={state.lastAction}/>), state.taskDescription && (
                            <TaskDescription description={state.taskDescription}/>), state.regionClsList && (
                            <ClassSelectionMenu
                                selectedCls={state.selectedCls}
                                regionClsList={state.regionClsList}
                                onSelectCls={action("SELECT_CLASSIFICATION", "cls")}
                            />), state.labelImages && (<TagsSidebarBox
                                currentImage={activeImage}
                                imageClsList={state.imageClsList}
                                imageTagList={state.imageTagList}
                                onChangeImage={action("CHANGE_IMAGE", "delta")}
                                expandedByDefault
                            />), // (state.images?.length || 0) > 1 && (
                            //   <ImageSelector
                            //     onSelect={action("SELECT_REGION", "region")}
                            //     images={state.images}
                            //   />
                            // ),
                            <RegionSelector
                                regions={activeImage ? activeImage.regions : emptyArr}
                                onSelectRegion={action("SELECT_REGION", "region")}
                                onDeleteRegion={action("DELETE_REGION", "region")}
                                onChangeRegion={action("CHANGE_REGION", "region")}
                            />, state.keyframes && (<KeyframesSelector
                                    onChangeVideoTime={action("CHANGE_VIDEO_TIME", "newTime")}
                                    onDeleteKeyframe={action("DELETE_KEYFRAME", "time")}
                                    onChangeCurrentTime={action("CHANGE_VIDEO_TIME", "newTime")}
                                    currentTime={state.currentVideoTime}
                                    duration={state.videoDuration}
                                    keyframes={state.keyframes}
                                />), <HistorySidebarBox
                                history={state.history}
                                onRestoreHistory={action("RESTORE_HISTORY")}
                            />,].filter(Boolean) : []}
                    >
                        {canvas}
                    </Workspace>
                    <SettingsDialog
                        open={state.settingsOpen}
                        onClose={() => dispatch({
                            type: "HEADER_BUTTON_CLICKED", buttonName: "Settings",
                        })}
                    />
                </HotkeyDiv>
            </FullScreen>
        </FullScreenContainer>)
}

export default MainLayout
