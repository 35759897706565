import React from "react";
import {useDataContext} from "../../../services/data.service";

const Card = ({
    showDeleteButton,
    style,
    tagStyle,
    onClick,
    onDelete,
    onChange,
    id,
    title,
    color,
    label,

    cardDraggable,
    editable
}) => {

    const {
        setHoveredRegion
    } = useDataContext();

    return (
        <div data-id={id}
             onClick={onClick}
             style={style}
             onMouseEnter={() => setHoveredRegion(id)}
             onMouseLeave={() => setHoveredRegion(null)}
             className="bg-white rounded mb-3 px-3 py-2 hover:bg-blue-100 hover:text-blue-800 cursor-pointer flex gap-2 items-center shadow text-base"><div className="w-2 h-2 rounded-full" style={{backgroundColor: color}}/>{title}</div>
    )
}

export default Card;