import styles from "./styles"
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import classnames from "classnames";
const useStyles = makeStyles(styles)

export const AutoPoint = ({key, x, y, type, onClick = () => {}}) => {
    const classes = useStyles()



    const coords = {
            left: x,
            top: y - 8 / 2,
        }

    return (
        <div
            key={key}
            style={{
                position: "absolute",
                ...coords,
                zIndex: 10,
            }}
        >
            <Paper
                onClick={() => onClick()}
                className={classnames(classes.autoPoint, {
                    foreground: type === 1,
                    background: type === 2
            })} />
        </div>
    )
}