import React, { createContext, useState, useEffect, useContext } from "react";
import {useAuthContext} from "./auth.service";
import axios from "axios";
import useSWR from "swr";
import {API_URL} from "./api.service";
import {toast} from "react-hot-toast";
import {useFullScreenHandle} from "react-full-screen";

export const DataContext = createContext(null);

const DataProvider = props => {
    const { token } = useAuthContext();

    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentDataSource, setCurrentDataSource] = useState(undefined);
    const [currentRecord, setCurrentRecord] = useState(undefined);
    const [hasToken, setHasToken] = useState(false);
    const [serverErrorNotified, setServerErrorNotified] = useState(false);
    const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const fullScreenHandle = useFullScreenHandle();
    const [hoveredRegion, setHoveredRegion] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modal, setModal] = useState(null);

    /* The first time the component is rendered, it tries to
     * fetch some data from the API.
     */

    let errorCount = 0;
    const ERROR_THRESHOLD = 3;
    const OBJECT_TYPES_URL = `${API_URL}/object-types`;
    const getObjectTypes = () => axios.get(OBJECT_TYPES_URL,
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => res.data);

    const {
        data: objectTypes,
        error: objectTypesError
    } = useSWR(hasToken ? OBJECT_TYPES_URL : null, getObjectTypes);
    if (objectTypesError) errorCount++;

    const OBJECTS_URL = `${API_URL}/objects`;
    const getObjects = () => axios.get(OBJECTS_URL,
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => res.data);

    const {
        data: objects,
        error: objectsError
    } = useSWR(hasToken ? OBJECTS_URL : null, getObjects);
    if (objectsError) errorCount++;

    const DATASETS_URL = `${API_URL}/datasets`;
    const getDatasets = () => axios.get(DATASETS_URL,
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => res.data);

    const {
        data: datasets,
        error: datasetsError
    } = useSWR(hasToken ? DATASETS_URL : null, getDatasets);
    if (datasetsError) errorCount++;

    const IMAGE_STATUSES_URL = `${API_URL}/images/statuses`;
    const getImageStatuses = () => axios.get(IMAGE_STATUSES_URL,
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => res.data)

    const {
        data: imageStatuses,
        error: imageStatusesError
    } = useSWR(hasToken ? IMAGE_STATUSES_URL : null, getImageStatuses);
    if (imageStatusesError) errorCount++;

    const USER_TYPES_URL = `${API_URL}/user-types`;
    const getUserTypes = () => axios.get(USER_TYPES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }})
        .then(result => result.data)

    const {
        data: userTypes,
        error: userTypesError
    } = useSWR(hasToken ? USER_TYPES_URL : null, getUserTypes);
    if (userTypesError) errorCount++;

    if (errorCount >= ERROR_THRESHOLD && !serverErrorNotified) {
        toast.error("Could not connect to the server. Please refresh and try again.", {
            position: "top-center"
        });

        setServerErrorNotified(true);
    }

    const enterFullScreen = () => {
        if (!fullScreenHandle) return;

        fullScreenHandle.enter();
        setIsFullScreenOpen(true);
    }

    const exitFullScreen = () => {
        if (!fullScreenHandle) return;

        fullScreenHandle.exit();
        setIsFullScreenOpen(false);
    }

    useEffect(() => {
        if (token && !hasToken) {
            setHasToken(true);
        }

        if (!!userTypes && !!imageStatuses && !!objectTypes)
            setDataLoaded(true);

    }, [token, hasToken, userTypes, imageStatuses, objectTypes]);

    return <DataContext.Provider value={{
        currentDataSource, setCurrentDataSource,
        currentRecord, setCurrentRecord,
        objectTypes, objects, imageStatuses,
        userTypes,
        datasets,
        fullScreenHandle, isFullScreenOpen,
        enterFullScreen, exitFullScreen,
        isMenuOpen, setIsMenuOpen,
        dataLoaded,
        hoveredRegion, setHoveredRegion,
        modal, setModal,
        modalOpen, setModalOpen
    }} {...props} />;
};

export const useDataContext = () => useContext(DataContext);

export default DataProvider;
