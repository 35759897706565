export function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) =>
            val.id == b[index].id &&
            val.cls == b[index].cls &&
            val.parent == b[index].parent);
}

export function debounceAsync(func, wait) {
    let timeout;

    return async function(...args) {
        return new Promise((resolve, reject) => {
            const later = async () => {
                clearTimeout(timeout);
                try {
                    // Await the function call and resolve the promise with its result
                    const result = await func(...args);
                    resolve(result);
                } catch (error) {
                    reject(error);
                }
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        });
    };
}

// Example usage w