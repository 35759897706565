import React, {useEffect, useRef, useState} from "react";
import Board from 'react-trello';
import Card from "./Card";
import LaneHeader from "./LaneHeader";
import isEqual from "lodash/isEqual";
const resolveConfig = require("tailwindcss/resolveConfig");
const tailwindConfig = require("tailwindcss/defaultTheme");
const { theme } = resolveConfig(tailwindConfig);

const AssignmentBoard = ({regions, updateAssignments}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(undefined);

    if (!data) {
        setLoading(true);

        const poles = [];
        const objects = [];
        regions.forEach(region => {
            if (region.cls === "Pole")
                poles.push(region);
            else
                objects.push(region);
        })

        const lanes = [
            {
                id: 'unassigned_lane',
                title: 'Unassigned Objects',
                cards: objects.filter(x => !x.parent).map((obj, i) => ({
                    id: obj.id,
                    title: obj.cls,
                    color: obj.color
                }))
            }
        ]

        poles.forEach((pole, i) => {
            lanes.push({
                id: pole.id,
                title: `Pole #${i + 1}`,
                color: pole.color,
                cards: objects.filter(x => x.parent == pole.id).map((obj, i) => ({
                    id: obj.id,
                    title: obj.cls,
                    color: obj.color
                }))
            })
        })

        setLoading(false);
        setData({
            lanes
        });
    }

    return (
         loading ? <div>Loading, please wait...</div> :
                <Board laneDraggable={false} data={data}
                       style={{background: "transparent", width: "100%", height: "100%", padding: theme.padding["3"]}}
                       laneStyle={{
                           background: theme.colors.slate["300"],
                           borderRadius: theme.borderRadius["md"],
                           paddingLeft: theme.padding["4"],
                           paddingRight: theme.padding["4"],
                           paddingBottom: theme.padding["2"]
                        }}
                       onDataChange={newData => updateAssignments(newData)}
                       components={{
                           Card,
                           LaneHeader
                       }}

                />
    )
}

export default AssignmentBoard;
