import React from "react";
import Button from "../_core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const ImageControls = ({currentImage, setCurrentImage, imageCount}) => {
    return (
        <div className="text-xl flex items-center gap-4">
            <Button style="muted" size="small" disabled={currentImage === 0}
                    onClick={() => setCurrentImage(currentImage - 1)}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </Button>
            <h2 className="align-middle">
                Image <strong>{currentImage + 1}/{imageCount}</strong>
            </h2>
            <Button style="muted" size="small" disabled={currentImage === (imageCount - 1)}
                    onClick={() => setCurrentImage(currentImage + 1)}>
                <FontAwesomeIcon icon={faChevronRight}/>
            </Button>
        </div>
    )
}

export default ImageControls;