import React, {useEffect, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useAuthContext} from "../services/auth.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBarsStaggered, faCheckSquare,
    faDiagramPredecessor,
    faDownload,
    faDrawPolygon,
    faEllipsis,
    faFilter,
    faFloppyDisk, faListCheck,
    faSpinner,
    faSquarePen,
    faUpRightAndDownLeftFromCenter
} from "@fortawesome/free-solid-svg-icons";
import {faImages} from "@fortawesome/free-regular-svg-icons";
import Button from "../components/_core/Button";
import Select from "react-select";
import {useDataContext} from "../services/data.service";
import axios from "axios";
import {API_URL} from "../services/api.service";
import useSWR from "swr";
import {toast} from "react-hot-toast";
import Annotator from "../components/AnnotationTool/Annotator";
import AssignmentBoard from "../components/Annotator/Assignment/Board";
import ImagesList from "../components/Annotator/ImagesList";
import ImageControls from "../components/Annotator/ImageControls";
import PageControls from "../components/Annotator/PageControls";
import makeImmutable, {asMutable} from "seamless-immutable"
import {isEqualWith} from "lodash";
import ValidationPanel from "../components/Annotator/Validation/Panel";
import ONNXProvider from "../services/onnx.service";

const MenuButton = ({
                        icon = faEllipsis, onClick = () => {
    }, isActive = false, label = "Item"
                    }) => {
    let baseClasses = "border-slate-700 text-slate-700 hover:bg-white hover:border-blue-500 hover:text-blue-500";

    if (isActive) baseClasses = "border-transparent text-white bg-slate-700/90 hover:bg-blue-600/90"

    return (<button onClick={onClick}
                    className={baseClasses + " text-center uppercase text-xs font-bold first-of-type:rounded-l last-of-type:rounded-r only-of-type:rounded only-of-type:border border-t border-b border-l last-of-type:border-r px-3 py-1.5 hover:ring"}>
            <FontAwesomeIcon icon={icon} className="mr-2 text-base"/>
            {label}
        </button>)
}

const MODES = {
    Annotate: "annotate",
    Validate: "validate",
    Assign: "assign"
};

const AnnotatorPage = () => {
    const {token} = useAuthContext();

    const {
        imageStatuses, objects, objectTypes, enterFullScreen, setModalOpen, setModal
    } = useDataContext();

    const [searchParams, setSearchParams] = useSearchParams();
    const [loaded, setLoaded] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [currentImageDetails, setCurrentImageDetails] = useState(null);
    const [images, rawSetImages] = useState([]);
    const [imageAttachments, setImageAttachments] = useState([]);
    const [originalRegions, setOriginalRegions] = useState(undefined);
    const [regions, setRegions] = useState(undefined);
    const [imageListOpen, setImageListOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const toggleImageListOpen = () => setImageListOpen(!imageListOpen);

    const setImages = newImages => {
        rawSetImages(newImages);

        if (!originalRegions) {
            setDirty(false);
            return;
        }

        const newRegions = newImages.length !== 0 ? newImages[0].regions : [];
        const same = isEqualWith(newRegions, originalRegions, (val1, val2, key) => {
            if (key === "parent") {
                return val1 == val2;
            }

            return ["highlighted", "editingLabels"].includes(key) ? true : undefined;
        });
        if (same || !loaded) {
            setDirty(false);
        } else {
            setDirty(true);
        }
    }

    const [mode, rawSetMode] = useState(MODES.Annotate);

    const setMode = newMode => {
        if (!Object.values(MODES).includes(newMode)) return;

        const doSetMode = () => {
            const mutable_images = asMutable(images, {deep: true});

            for (let i = 0; i < mutable_images[0].regions.length; i++) {
                mutable_images[0].regions[i].highlighted = false;
                mutable_images[0].regions[i].editingLabels = false;
            }

            setRegions(mutable_images.length !== 0 ? mutable_images[0].regions : [])
            rawSetMode(newMode);
        }

        if (dirty) {
            setModal({
                title: "Are you sure?",
                content: "You have unsaved changes, are you sure you'd like to change modes?",
                okButtonFn: () => doSetMode()
            })

            setModalOpen(true);
        } else {
            doSetMode();
        }
    }

    const currentImage = parseInt(searchParams.get("img")) - 1;
    const currentPage = parseInt(searchParams.get("page"));
    const currentDatasets = searchParams.get("datasets");

    const DATASET_IMAGES_URL = `${API_URL}/images?datasets=${currentDatasets}&page=${currentPage}`
    const fetchDatasetImages = async () => await axios.get(DATASET_IMAGES_URL, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
        .then(result => result.data)
        .catch(error => console.error("error", error));

    const {
        data: datasetImages, error: datasetImagesError
    } = useSWR(DATASET_IMAGES_URL, fetchDatasetImages, {revalidateOnFocus: false});
    if (datasetImagesError) console.error(datasetImagesError);

    const imageCount = datasetImages ? datasetImages.resultCount : 0;
    const pageCount = datasetImages ? datasetImages.pageCount : 0;

    let activeCancelTokenSource = null;

    const getImageDetails = (imageId) => {
        // Cancel the active task, if it exists
        if (activeCancelTokenSource) {
            activeCancelTokenSource.cancel('Task chain cancelled');
        }

        // Create a new cancel token source
        activeCancelTokenSource = axios.CancelToken.source();

        new Promise((resolve) => {
            setLoaded(false);
            resolve();
        }).then(() => {
            const getImageDetailsPromise = axios.get(`${API_URL}/images/${imageId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }, cancelToken: activeCancelTokenSource.token
            }).then(response => response.data);

            const getAnnotationsPromise = axios.get(`${API_URL}/images/${imageId}/annotations`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }, cancelToken: activeCancelTokenSource.token
            }).then(response => response.data);

            const getAttachmentsPromise = axios.get(`${API_URL}/images/${imageId}/attachments`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }, cancelToken: activeCancelTokenSource.token
            }).then(response => response.data);

            return Promise.all([getImageDetailsPromise, getAnnotationsPromise, getAttachmentsPromise]);
        }).then(([imageDetails, annotationData, attachmentData]) => {
            setCurrentImageDetails(imageDetails.result);

            if (annotationData && annotationData.regions) {
                setOriginalRegions(annotationData.regions);
                setRegions(annotationData.regions);

                setImages([{
                    src: imageDetails.result.url, name: imageDetails.result.name, regions: annotationData.regions,
                    attachments: attachmentData.data
                }])
            }
        }).then(() => setLoaded(true))
            .catch(error => {
                if (!axios.isCancel(error)) {
                    toast.error("Failed to get data: " + error);
                }
            });
    }

    const setCurrentImage = (newImage) => {
        if (typeof newImage !== "number") return;

        if (!currentImage || newImage !== currentImage) {
            setImageListOpen(false);
            setOriginalRegions(undefined);
        }

        searchParams.set("img", (newImage + 1).toString());
        setSearchParams(searchParams);
    }


    const setCurrentPage = (newPage) => {
        if (typeof newPage !== "number") return;

        searchParams.set("page", newPage.toString());
        setSearchParams(searchParams);

        setCurrentImage(0);
    }


    const currentStatus = currentImageDetails ? imageStatuses.find(status => status.id === currentImageDetails.status) : imageStatuses[0];

    const currentOption = {
        value: currentStatus.id, label: currentStatus.name
    }

    const image_name = currentImageDetails ? `${currentImageDetails.dataset_id}-${currentImageDetails.sequence}` : null;

    const updateStatus = (newStatus) => {
        if (newStatus.value === currentStatus) return;

        axios.post(`${API_URL}/images/${currentImageDetails.id}`, {
            status: newStatus.value
        }, {headers: {"Authorization": `Bearer ${token}`}})
            .then(result => {
                if (result.data.success) {
                    toast.success(`Saved status for image ${image_name}`, {position: "top-center"});
                    getImageDetails(currentImageDetails.id);
                }
            })
    }

    const saveAnnotations = () => {
        setIsSaving(true);
        axios.post(`${API_URL}/images/${currentImageDetails.id}/annotations`, JSON.stringify(images[0]), {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                toast.success(`Saved annotations for image ${image_name} successfully.`, {position: "top-center"})
                getImageDetails(currentImageDetails.id);
            } else {
                toast.error("Failed to save: " + res.data.error);
            }

        }).catch(err => {
            toast.error("Unexpected error: " + err.toString(), {position: "top-center"})
        }).finally(() => {
            setIsSaving(false);
        })
    }

    useEffect(() => {
        let valueChanged = false;

        if (!searchParams.has("img")) {
            searchParams.set("img", (1).toString());
            valueChanged = true;
        }

        if (!searchParams.has("page")) {
            searchParams.set("page", (1).toString());
            valueChanged = true;
        }
        if (valueChanged && setSearchParams) setSearchParams(searchParams);
    }, [searchParams, setSearchParams])

    useEffect(() => {
        if (!datasetImages || !getImageDetails) return;
        
        const currentImageID = datasetImages.results[currentImage].id;
        
        if ((!loaded || currentImageDetails.id !== currentImageID) || (loaded && !currentImageDetails))
            getImageDetails(currentImageID);
    }, [currentImage, currentImageDetails, datasetImages, getImageDetails, loaded])

    useEffect(() => {
        document.title = "GT2 - Annotator" + (dirty ? " (unsaved changes)" : "")
    }, [dirty]);

    const updateAssignments = (newLanes) => {
        if (!newLanes || newLanes.lanes.length === 0) return;

        const mutable_images = asMutable(images, {deep: true});

        for (let i = 0; i < mutable_images[0].regions.length; i++) {

            for (let j = 0; j < newLanes.lanes.length; j++) {
                if (newLanes.lanes[j].cards.find(el => el.id === mutable_images[0].regions[i].id)) {

                    if (newLanes.lanes[j].id === "unassigned_lane") mutable_images[0].regions[i].parent = null; else mutable_images[0].regions[i].parent = newLanes.lanes[j].id;
                }
            }
        }

        setImages([...mutable_images])
    }

    return (<ONNXProvider>
        <div className="overflow-hidden w-full h-full flex flex-col">
            <div className="flex gap-4 items-center bg-slate-100 py-2 px-4 h-14 w-full">
                <div className=" border-r border-slate-400 pr-4">
                    <MenuButton icon={faImages} label="Images" onClick={() => toggleImageListOpen()}
                                isActive={imageListOpen}/>
                </div>
                {imageListOpen ? <>
                    <div className="text-3xl font-bold flex items-center flex-grow gap-2">
                        <h2 className="align-middle mr-4">
                            Image List
                        </h2>
                        {/*<Button style="muted" size="small"><FontAwesomeIcon icon={faFilter}/></Button>*/}
                    </div>
                    <PageControls currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount}/>

                </> : <>
                    <div className="text-3xl font-bold flex items-center flex-grow gap-2">
                        <h2 className="align-middle">
                            <FontAwesomeIcon icon={faSquarePen} className="mr-2"/>
                            <span className="hidden lg:inline-block">Annotator</span>
                        </h2>
                    </div>
                    <Button disabled={!dirty || isSaving} extraClasses={"w-24"}
                            onClick={() => saveAnnotations()}>{isSaving ?
                        <FontAwesomeIcon icon={faSpinner} className="animate-spin"/> : <>
                            <FontAwesomeIcon icon={faFloppyDisk}
                                             className="mr-2"/>Save
                        </>}</Button>
                    <Button disabled={!dirty || isSaving} style="muted"
                            onClick={() => getImageDetails(datasetImages.results[currentImage].id)}>Discard
                        Changes</Button>
                    <div className="flex justify-end items-center border-l border-slate-400 pl-4">
                        <h3 className="text-lg text-slate-600 mr-4">Mode</h3>
                        <MenuButton icon={faDrawPolygon} label="Annotate" isActive={mode === MODES.Annotate}
                                    onClick={() => setMode(MODES.Annotate)}/>
                        <MenuButton icon={faCheckSquare} label="Validate" isActive={mode === MODES.Validate}
                                    onClick={() => setMode( MODES.Validate)}/>
                        <MenuButton icon={faBarsStaggered} label="Assign" isActive={mode === MODES.Assign}
                                    onClick={() => setMode( MODES.Assign)}/>
                    </div>
                </>}

            </div>
            <main className="overflow-hidden relative flex flex-col flex-grow">
                {imageListOpen ? <ImagesList currentPage={currentPage} currentImageID={currentImageDetails.id}
                                             searchParams={searchParams} setCurrentImage={setCurrentImage}/> : null}
                <div className="flex gap-4 items-center bg-white py-2 px-4 h-14 w-full">
                    <ImageControls currentImage={currentImage} setCurrentImage={setCurrentImage}
                                   imageCount={imageCount}/>
                    of
                    <PageControls currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} simple/>
                    <div className="border-l border-slate-400 pl-4">
                        {mode === MODES.Annotate && <Button style="muted" size="small" onClick={() => enterFullScreen()}>
                            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter}/>
                        </Button>}
                    </div>
                    <div className="flex-grow"/>
                    <div className="border-l border-slate-400 flex gap-4 items-center pl-4 place-self-end">
                        <h3 className="text-lg text-slate-600">Status</h3>
                        <Select
                            styles={{
                                menu: (provided) => ({
                                    ...provided, zIndex: 30
                                })
                            }}
                            className="w-56"
                            value={currentOption}
                            onChange={updateStatus}
                            options={imageStatuses.map(status => {
                                return {value: status.id, label: status.name};
                            })}/>
                    </div>
                </div>
                <div className="flex flex-grow flex-1">
                    <div id="annotator"
                         className={(mode === MODES.Annotate ? "w-full flex-1" : "w-1/2" )}>
                        {images && images.length > 0 && currentImageDetails && objects && loaded ? <Annotator
                            hideHeader
                            setImages={setImages}
                            regionClsList={objects.map(object => object.name).sort()}
                            enabledTools={["select", "zoom", "pan", "create-point", "create-sam-points", "create-polygon", "create-box"]}
                            selectedImage={0}
                            images={images}
                            minimalMode={mode !== MODES.Annotate}
                        /> : "Loading..."}
                    </div>
                    {mode === MODES.Assign ? <div className="flex flex-col overflow-hidden w-1/2 flex-1">
                        <div
                            className="px-4 py-3 bg-white border-t border-b border-slate-400 ">
                            <h3 className="text-2xl font-bold">
                                <FontAwesomeIcon icon={faDiagramPredecessor} className="mr-2"/>
                                Object Assignments
                            </h3>
                            <span className="text-sm text-slate-600">Hover over an object in the lists below to highlight it in the image.</span>
                        </div>
                        <div className="bg-slate-100 flex-grow" id="assignments">
                            <AssignmentBoard regions={regions}
                                             updateAssignments={updateAssignments}/>
                        </div>
                    </div> : null}
                    {mode === MODES.Validate ? <div className="flex flex-col overflow-hidden w-1/2 flex-1">
                        <div
                            className="px-4 py-3 bg-white border-t border-b border-slate-400 ">
                            <h3 className="text-2xl font-bold">
                                <FontAwesomeIcon icon={faListCheck} className="mr-2"/>
                                Object Validation
                            </h3>
                            <span className="text-sm text-slate-600">Validate if objects appear in this image or not.</span>
                        </div>
                        <div className="bg-slate-100 flex-grow" id="assignments">
                            <ValidationPanel />
                        </div>
                    </div> : null}
                </div>
            </main>
        </div>
    </ONNXProvider>

    )
}

export default AnnotatorPage;
