import React, {useEffect, useRef} from "react";
import Header from "./Header";
import {useDataContext} from "../services/data.service";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "./_core/Button";

const Layout = ({ children,
                }) => {
    const {isMenuOpen, setModalOpen, modal, modalOpen} = useDataContext();

    const modalEl = useRef(null);

    useEffect(() => {
        if (modalEl && modalEl.current) {
            const handleClickOutside = event => {
                if (!modalEl.current.contains(event.target)) {
                    setModalOpen(false);
                }
            };

            document.addEventListener("mousedown", handleClickOutside);

            return () => document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [modalEl])

    return (
        <div className="w-full h-screen overflow-hidden flex flex-col relative">
            <Header />
            <div className={"flex items-center justify-center"} style={{height: "calc(100vh - 48px)"}}>
                {children}
            </div>
            { modalOpen && <div ref={modalEl} className="absolute w-96 top-36 mx-auto left-0 right-0 bg-white flex flex-col shadow-lg" style={{zIndex: 100}}>
                <div className="px-4 py-3 flex">
                    <h3 className="text-2xl text-slate-600 font-bold flex-grow">{modal ? modal.title : "Are you sure?"}</h3>
                    <Button style="muted" onClick={() => setModalOpen(false)}>
                        <FontAwesomeIcon icon={faXmark} size={"1x"}/>
                    </Button>
                </div>
                <div className="p-4 bg-slate-100 flex-grow">
                    { modal ? modal.content : "Are you sure?"}
                </div>
<div
                className="flex items-end flex-row-reverse p-4 gap-4">
                    <Button onClick={() => modal?.okButtonFn?.()}>
                        {modal?.okButtonText ?? "Yes"}
                    </Button>
                    <Button style="muted" onClick={() => setModalOpen(false)}>Cancel</Button>
                </div>
            </div>
            }
            { isMenuOpen || modalOpen ? <div className="absolute inset-0 top-12 bg-gray-500/20 backdrop-blur-sm" style={{zIndex: 99}} /> : null }
        </div>
    )
}

export default Layout;