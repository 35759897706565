import cv from "@techstark/opencv-js";

export const bakeMaskToPolygon = ({maskImageData, detailLevel}) => {
    try {
        const maskImg = cv.matFromImageData(maskImageData);
        cv.cvtColor(maskImg, maskImg, cv.COLOR_BGR2GRAY)
        const threshMask = new cv.Mat();
        cv.threshold(maskImg, threshMask, 100, 255, cv.THRESH_BINARY)
        const contours = new cv.MatVector();
        const hierarchy = new cv.Mat();

        cv.findContours(threshMask, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);

        let largestContour = contours.get(0);
        let largestContourArea = cv.contourArea(largestContour);

        // Iterate over the contours to find the largest one
        for (let i = 1; i < contours.size(); i++) {
            let contour = contours.get(i);
            let area = cv.contourArea(contour);

            if (area > largestContourArea) {
                largestContourArea = area;
                largestContour = contour;
            }
        }


        const detailLevelEpsilon = detailLevel === 2 ? 0.0005 : detailLevel === 1 ? 0.001 : 0.005
        const epsilon = detailLevelEpsilon * cv.arcLength(largestContour, true);
        const approx = new cv.Mat();
        cv.approxPolyDP(largestContour, approx, epsilon, true)
        const polygon = [];

        for (let j = 0; j < approx.rows; j++) {
            polygon.push([approx.data32S[j*2] / maskImageData.width, approx.data32S[j*2+1] / maskImageData.height]);
        }

        approx.delete();
        contours.delete();
        hierarchy.delete();

        return polygon;
    } catch (err) {
        console.error(cv.exceptionFromPtr(err))
    }
}

