import React from "react";
import {useLocation} from "react-router-dom";
import Button from "../_core/Button";
import NavMenu from "./NavMenu";
import {useAuthContext} from "../../services/auth.service";
import {useDataContext} from "../../services/data.service";
import User from "./User";
import Logo from "../_core/logo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

const Header = props => {
    const {token, user} = useAuthContext();

    const {
        currentDataSource,
        currentRecord
    } = useDataContext();

    const location = useLocation();
    const pathname = location.pathname;


    return (
        <nav
            className="bg-slate-700 text-white h-12 w-full flex items-center justify-between select-none"
            {...props}

        >
            <div className="flex items-center">
                { token ? <NavMenu/> : null }
                <Logo className="h-6 ml-4 mr-2"/>
                <h1 className="text-xl font-bold flex items-center whitespace-nowrap">
                    IKE Insight
                </h1>
            </div>
            {pathname.startsWith("/annotator") ?
                <Button as={"Link"} to="/datasets"
                        extraClasses="justify-self-start ml-4 mr-auto">
                    <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                    Back to Datasets
                </Button>
                : null}

            {user ? <User/> : null}
        </nav>
    );
};

export default Header;
