import React from "react";
import {useDataContext} from "../../../services/data.service";
import CollapsePane from "./CollapsePane";

const ValidationPanel = () => {
    const {
        objectTypes
    } = useDataContext();

    const ready = !!objectTypes;

    return (
        !ready ? <div>Loading, please wait...</div> : <div className="p-3">
            {objectTypes
                .sort((a, b) => a.name > b.name).filter(x => x.name !== "Core Objects")
                .map((objectType) => <CollapsePane objectType={objectType} />)}
        </div>
    )
};

export  default ValidationPanel;
