import {AutoPoint} from "./AutoPoint";

const AutoPoints = ({
                        points, togglePoint, layoutParams, mat
                    }) => {


    return points
        .map((point, index) => {
            const [x, y, type] = point;
            const {iw, ih} = layoutParams.current;

            const margin = 8;

            const coords = mat.clone().inverse().applyToPoint(x * iw, y * ih)

            return (<AutoPoint key={index}
                               x={coords.x - margin}
                               y={coords.y - margin}
                               type={type} onClick={() => togglePoint(index)}/>)
        });
}

export default AutoPoints;