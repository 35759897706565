import React, {useState} from "react";
import {useDataContext} from "../../../services/data.service";
import {faCaretDown, faCaretUp, faListCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Checkbox = ({id, label, isChecked, checkChanged}) => {
    return (
        <div className="flex items-center">
            <input id={id} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-900 rounded focus:ring-blue-500 focus:ring-2" onChange={checkChanged?.(!isChecked)} />
            <label htmlFor={id} className="ml-2 text-gray-700 whitespace-nowrap">{label}</label>
        </div>
    )
}

const CollapsePane = ({objectType}) => {
    const { objects } = useDataContext();
    const [expanded, setExpanded] = useState(true);
    const ready = !!objects && !!objectType;

    if (!ready) return;

    const relevantObjects = objects.filter(x => x.type === objectType.id);

    if (!relevantObjects || relevantObjects.length === 0) return;

    return (
        <div key={objectType.name} className="bg-gray-50 rounded mb-3 last:mb-0 overflow-hidden">
            <div className="px-3 py-2 bg-white border-b border-gray-300 shadow cursor-pointer flex justify-between items-center" onClick={() => setExpanded(!expanded)}><h3 className="text-xl text-gray-600 font-semibold ">{objectType.name}</h3> <FontAwesomeIcon icon={expanded ? faCaretDown : faCaretUp} className="w-5 h-5 text-gray-500"/></div>
            { expanded ? <div className="p-3 flex gap-x-6 gap-y-3 flex-wrap">{relevantObjects.map((object, i) => <Checkbox id={`obj_${object.id}`} label={object.name}/>)}</div> : null }
        </div>
    )
}

export default CollapsePane;
