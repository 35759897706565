import React, {createContext, useContext, useEffect, useState} from "react";
import useSWR from "swr";
import axios from "axios";
import {API_URL} from "./api.service";
import {toast} from "react-hot-toast";

export const AuthContext = createContext(null);


const AuthProvider = props => {
    const [token, setToken] = useState(undefined);
    const [authLoaded, setAuthLoaded] = useState(false);

    /* The first time the component is rendered, it tries to
     * fetch the header data from a source, like a cookie or
     * the localStorage.
     */

    const USER_SELF_URL = `${API_URL}/user/self`;
    const getUser = () => axios.get(USER_SELF_URL,
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res => res.data.user)

    const {
        data: user,
        error: userError
    } = useSWR(token ? USER_SELF_URL : null, getUser);

    if (userError) console.error(userError);

    useEffect(() => {
        const storedToken = localStorage.getItem("token");

        if (storedToken && !token) {
            setToken(storedToken);
        }

        setAuthLoaded(true);
    }, [token]);

    const logout = () => {
        setToken(undefined);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
    };

    const login = (username, password) => {
        axios.post(API_URL + "/user/login", {username, password})
            .then(result => {
                if (result.status === 200) {
                    if (!result.data.success) {
                        toast.error("Username or password is incorrect.", {position: "top-center"});
                    } else {
                        const token = result.data.token;
                        toast.success(`Logged in as ${result.data.user.name}!`, {position: "top-center"});
                        setToken(token);
                        localStorage.setItem("token", token);
                    }
                }
            })
    };

    return <AuthContext.Provider value={{token, user, login, logout, authLoaded}} {...props} />;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
