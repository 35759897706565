
import { grey } from "@material-ui/core/colors"

export default {
    autoPoint: {
        fontSize: 12,
        cursor: "default",
        transition: "opacity 200ms",
        opacity: 0.75,
        "&:hover": {
            opacity: 0.9,
            cursor: "pointer",
        },
        // pointerEvents: "none",
        fontWeight: 600,
        color: grey[900],
        border: "1px solid",
        borderColor: grey[900],
        padding: 8,
        "&.foreground": {
            background: "rgb(100,255,95)",
        },
        "&.background": {
            background: "rgb(255,93,64)",
        },
    },
}
