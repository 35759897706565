import React from "react";
import {useEffect, useState} from "react";
import {API_URL} from "../../services/api.service";
import axios from "axios";
import useSWR from "swr";
import {useAuthContext} from "../../services/auth.service";
import {useVirtual} from "react-virtual";
import AutoSizer from "react-virtualized-auto-sizer";
import Button from "../_core/Button";
import {useDataContext} from "../../services/data.service";


const ImagesList = ({ currentPage, currentImageID, searchParams, setCurrentImage }) => {

    const {token} = useAuthContext();
    const [dataCount, setDataCount] = useState(0);

    const {
        imageStatuses
    } = useDataContext();

    const datasetParams = new URLSearchParams(searchParams);
    datasetParams.set("page", currentPage.toString());
    const DATASET_IMAGES_URL = `${API_URL}/images?${datasetParams.toString()}`
    const fetchDatasetImages = async () =>
        await axios.get(DATASET_IMAGES_URL, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(result => result.data)
            .catch(error => console.log("error", error));

    const {
        data: datasetImages,
        error: datasetImagesError
    } = useSWR(DATASET_IMAGES_URL, fetchDatasetImages);
    if (datasetImagesError) console.error(datasetImagesError);

    useEffect(() => {
        if (datasetImages && datasetImages.resultCount) {
            setDataCount(datasetImages.resultCount)
        }
    }, [datasetImages])

    const parentRef = React.useRef();

    const rowVirtualizer = useVirtual({
        size: dataCount,
        parentRef,
        estimateSize: React.useCallback(() => 45, []),
        overscan: 3
    });

    const rowBG = (rowIndex) => {
        let matchingIndex = -1;
        if (currentImageID)
            matchingIndex = datasetImages.results.findIndex(x => x.id === currentImageID);

        if (matchingIndex !== -1 && matchingIndex === rowIndex)
            return "bg-blue-100 text-blue-700 hover:bg-blue-200"

        return "bg-white hover:bg-gray-200"
    }

    const ready = !!datasetImages && dataCount !== 0;

    return (
        <div className="bg-slate-200 absolute inset-0 z-50 flex flex-col">
            <div className={"w-full bg-white flex justify-between px-5 py-3 font-bold text-lg"}
            >
                <h3 className="w-40">Dataset</h3>
                <h3 className="w-24">Sequence</h3>
                <h3 className="w-96">Name</h3>
                <h3 className="w-36">Status</h3>
                <h3 className="w-32 text-right">Actions</h3>
            </div>
            <div className="w-full overflow-y-auto overflow-x-hidden flex-grow" ref={parentRef}>
            {!ready ?
                <div className="w-full h-full pt-36 text-center text-xl text-gray-500">{dataCount === 0 ? "No results found." : "Loading..."}</div> :
                <AutoSizer>
                    {({width, height}) => (
                        <div className={(rowVirtualizer.totalSize + 45) > height ? "pr-3" : ""}
                             style={{height, width}}
                        >
                            <div
                                className="pr-3 "
                                style={{
                                    height: `${rowVirtualizer.totalSize}px`,
                                    width: `${width}px`,
                                    position: "relative"
                                }}
                            >
                                {rowVirtualizer.virtualItems.map(virtualRow => datasetImages.results[virtualRow.index] ? (
                                    <div
                                        key={virtualRow.index}
                                        className={`flex hover:font-bold rounded justify-between items-center text-gray-600 ${(rowVirtualizer.totalSize + 45) > height ? 'pr-8' : 'pr-5'} pl-5 ${rowBG(virtualRow.index)}`}
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: `${virtualRow.size}px`,
                                            transform: `translateY(${virtualRow.start}px)`
                                        }}
                                    >
                                        <div className="w-40">{datasetImages.results[virtualRow.index].dataset_id}</div>
                                        <div className="w-24">{datasetImages.results[virtualRow.index].sequence}</div>
                                        <div className="w-96">{datasetImages.results[virtualRow.index].name}</div>
                                        <div className="w-36 whitespace-nowrap">
                                         <h3>{imageStatuses.find(x => x.id === datasetImages.results[virtualRow.index].status).name}</h3>
                                        </div>
                                        <div className={`w-32 text-right -mt-0.5`}><Button onClick={() => setCurrentImage(virtualRow.index)} size={"small"}>Open Image</Button></div>

                                    </div>
                                ) : null)}
                            </div>
                        </div>
                    )}
                </AutoSizer> }
            </div>
        </div>
    )
}

export default ImagesList;
