import React, {useEffect, useReducer} from "react"
import makeImmutable, {asMutable, without} from "seamless-immutable"

import MainLayout from "../MainLayout"
import SettingsProvider from "../SettingsProvider"
import combineReducers from "./reducers/combine-reducers.js"
import generalReducer from "./reducers/general-reducer.js"
import getFromLocalStorage from "../utils/get-from-local-storage"
import historyHandler from "./reducers/history-handler.js"
import imageReducer from "./reducers/image-reducer.js"
import useEventCallback from "use-event-callback"
import videoReducer from "./reducers/video-reducer.js"
import {arrayEquals} from "../../../js/utils";

import { isEqual } from "lodash";
import {useONNXContext} from "../../../services/onnx.service";

export const Annotator = ({
                              images,
                              attachments,
                              allowedArea,
                              selectedImage = images && images.length > 0 ? 0 : undefined,
                              showPointDistances,
                              pointDistancePrecision,
                              showTags = getFromLocalStorage("showTags", true),
                              enabledTools = [
                                  "select",
                                  "create-sam-points",
                                  "create-point",
                                  "create-box",
                                  "create-polygon",
                                  "create-line",
                                  "create-expanding-line",
                                  "show-mask",
                              ],
                              selectedTool = "select",
                              regionTagList = [],
                              regionClsList = [],
                              imageTagList = [],
                              imageClsList = [],
                              keyframes = {},
                              taskDescription = "",
                              fullImageSegmentationMode = false,
                              RegionEditLabel,
                              videoSrc,
                              videoTime = 0,
                              videoName,
                              onExit,
                              onNextImage,
                              onPrevImage,
                              setImages,
                              keypointDefinitions,
                              autoSegmentationOptions = {type: "autoseg"},
                              hideHeader,
                              hideHeaderText,
                              hideNext,
                              hidePrev,
                              hideClone,
                              hideSettings,
                              hideFullScreen,
                              hideSave,
                              allowComments,
                              minimalMode
                          }) => {

    const onnxContext = useONNXContext();

    if (typeof selectedImage === "string") {
        selectedImage = (images || []).findIndex((img) => img.src === selectedImage)
        if (selectedImage === -1) selectedImage = undefined
    }
    const annotationType = images ? "image" : "video"
    const [state, dispatchToReducer] = useReducer(
        historyHandler(
            combineReducers(
                annotationType === "image" ? imageReducer : videoReducer,
                generalReducer
            )
        ),
        makeImmutable({
            annotationType,
            showTags,
            allowedArea,
            showPointDistances,
            pointDistancePrecision,
            selectedTool,
            fullImageSegmentationMode: fullImageSegmentationMode,
            autoSegmentationOptions,
            mode: null,
            taskDescription,
            showMask: true,
            labelImages: imageClsList.length > 0 || imageTagList.length > 0,
            regionClsList,
            regionTagList,
            imageClsList,
            imageTagList,
            currentVideoTime: videoTime,
            enabledTools,
            history: [],
            samPoints: [],
            videoName,
            keypointDefinitions,
            allowComments,
            ...(annotationType === "image"
                ? {
                    selectedImage,
                    images,
                    selectedImageFrameTime:
                        images && images.length > 0 ? images[0].frameTime : undefined,
                }
                : {
                    videoSrc,
                    keyframes,
                }),
        })
    )

    const dispatch = useEventCallback((action) => {
        if (onnxContext) action.onnxContext = onnxContext;

        if (action.type === "HEADER_BUTTON_CLICKED") {
            if (["Exit", "Done", "Save", "Complete"].includes(action.buttonName)) {
                return onExit(without(state, "history"))
            } else if (action.buttonName === "Next" && onNextImage) {
                return onNextImage(without(state, "history"))
            } else if (action.buttonName === "Prev" && onPrevImage) {
                return onPrevImage(without(state, "history"))
            }
        }
        dispatchToReducer(action);

        if (setImages && !minimalMode) {
            if (action.type === "DELETE_REGION") {
                const currentState = without(state, "history");
                const newImageDetails = [asMutable(...currentState["images"])];

                newImageDetails[0].regions = newImageDetails[0].regions.filter(x => x.id !== action.region.id);

                setImages(newImageDetails);
            } else if (!["MOUSE_MOVE", "SELECT_REGION"].includes(action.type)) {
                setImages([...without(state, "history")["images"]]);
            } else {
                const newState = [...without(state, "history")["images"]];

                let region = undefined;
                if (action.hasOwnProperty("polygon"))
                    region = action.polygon;
                else if (action.hasOwnProperty("region"))
                    region = action.region;

                if (!region) return;

                const existingRegion = newState[0].regions.find(x => x.id === region.id);

                console.log([...newState])

                if (!existingRegion || !isEqual(existingRegion, region))
                    setImages([...newState])
            }
        }
    })

    const onRegionClassAdded = useEventCallback((cls) => {
        dispatchToReducer({
            type: "ON_CLS_ADDED",
            cls: cls,
        })
    })

    useEffect(() => {
        if (selectedImage === undefined) return
        dispatchToReducer({
            type: "SELECT_IMAGE",
            imageIndex: selectedImage,
            image: state.images[selectedImage],
            onnxContext: onnxContext
        })

    }, [selectedImage])

    if (!images && !videoSrc)
        return 'Missing required prop "images" or "videoSrc"'

    return (
        <SettingsProvider>
            <MainLayout
                RegionEditLabel={RegionEditLabel}
                alwaysShowNextButton={Boolean(onNextImage)}
                alwaysShowPrevButton={Boolean(onPrevImage)}
                state={state}
                dispatch={dispatch}
                onRegionClassAdded={onRegionClassAdded}
                hideHeader={hideHeader}
                hideHeaderText={hideHeaderText}
                hideNext={hideNext}
                hidePrev={hidePrev}
                hideClone={hideClone}
                hideSettings={hideSettings}
                hideFullScreen={hideFullScreen}
                hideSave={hideSave}
                minimalMode={minimalMode}
            />
        </SettingsProvider>
    )
}

export default Annotator
