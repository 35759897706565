import React from "react";
import {Link} from "react-router-dom";

const Button = ({
                    type = "button",
                    style,
                    as = "button",
                    onClick,
                    children,
                    disabled = false,
                    size = "normal",
                    extraClasses = "",
                    ...rest
                }) => {
    const basePadding = size === "small" ? "py-1 px-2" : "py-2 px-4"

    let baseClasses = basePadding + " inline-block rounded uppercase text-xs font-bold tracking-wide"
    let baseClassesDisabled = baseClasses + " cursor-not-allowed"


    if (style === "muted") {
        if (as === "button") {
            baseClasses += " bg-transparent text-slate-500 hover:bg-white hover:text-slate-700 hover:ring";
            baseClassesDisabled += " bg-transparent text-gray-300";
        } else if (as === "Link") {
            baseClasses += " bg-gray-500 text-slate-100 hover:bg-blue-600 hover:text-white opacity-90";
            baseClassesDisabled += " bg-transparent text-gray-300";
        }
    } else if (style === "secondary") {
        baseClasses += " bg-transparent text-slate-500 hover:bg-blue-600 border-2 border-slate-500 hover:text-slate-100 hover:border-blue-600";
        baseClassesDisabled += " bg-transparent text-gray-300";
    }
    else {
        baseClasses += " bg-slate-600 hover:bg-blue-600 text-slate-100"
        baseClassesDisabled += " bg-gray-300 text-gray-400"
    }

    let Element = ({children}) => {
        if (disabled) {
            return <button type={type}
                           className={`${baseClassesDisabled} ${extraClasses}`}
                           onClick={onClick} disabled {...rest}>{children}</button>
        } else return <button type={type}
                              className={`${baseClasses} ${extraClasses}`}
                              onClick={onClick} {...rest}>{children}</button>

    }

    if (as === "Link") Element = ({children}) => {
        if (disabled) return (
            <Link
                className={`${baseClassesDisabled} tracking-wide ${extraClasses}`} {...rest}>{children}</Link>
        )
        else return (
            <Link
                className={`${baseClasses}  ${extraClasses}`} {...rest}>{children}</Link>
        )
    }

    return (
        <Element>{children}</Element>
    )
}

export default Button;