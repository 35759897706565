import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAuthContext} from "../services/auth.service";
import {useDataContext} from "../services/data.service";

const PrivateRoute = ({
                          isAllowed,
                          redirectPath = '/',
                          children,
                      }) => {
    const {authLoaded, token, user} = useAuthContext();
    const {dataLoaded} = useDataContext();

    if (!authLoaded || !dataLoaded) return;
    else if (authLoaded && token && !user) return;

    if (!isAllowed) {
        return <Navigate to={redirectPath} replace/>;
    }

    return children ? children : <Outlet/>;
};

export default PrivateRoute;