import React from "react";

const Input = ({ type = "text", name, label="", className, required = false, onChange = () => {}, value = undefined, ...rest}) => {
    return (
        <>
            <label className={"text-gray-600 font-sans"} htmlFor={name}>
                {label}{
                required ?
                    <span className="ml-0.5 text-red-600">*</span> :
                    null
            }
            </label>
            <input type={type}
                   name={name}
                   required={required}
                   onChange={onChange}
                   value={value}
                   {...rest}
                   className={`
                      bg-white
                      rounded border 
                      border-gray-300 
                      focus:outline-none 
                      focus:ring
                      shadow-inner
                      focus:border-blue-300 
                      ${className ?? ''}`}
            />
        </>
    )

}

export default Input;