import React from "react";
import Button from "../_core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const PageControls = ({currentPage, setCurrentPage, pageCount, simple=false}) => {
    return (
        <div className="text-xl flex items-center gap-4">
            { !simple ? <Button style="muted" size="small" disabled={currentPage === 1}
                    onClick={() => setCurrentPage(1)}>
                <FontAwesomeIcon icon={faChevronLeft}/>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </Button> : null }
            <Button style="muted" size="small" disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </Button>
            <h2 className="align-middle">
                Page <strong>{currentPage}/{pageCount}</strong>
            </h2>
            <Button style="muted" size="small" disabled={currentPage === (pageCount)}
                    onClick={() => setCurrentPage(currentPage + 1)}>
                <FontAwesomeIcon icon={faChevronRight}/>
            </Button>
            {!simple ? <Button style="muted" size="small" disabled={currentPage === (pageCount)}
                    onClick={() => setCurrentPage(pageCount)}>
                <FontAwesomeIcon icon={faChevronRight}/>
                <FontAwesomeIcon icon={faChevronRight}/>
            </Button> : null}
        </div>
    )
}

export default PageControls;
