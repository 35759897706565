import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../services/auth.service";
import Logo from "../components/_core/logo";
import Button from "../components/_core/Button";
import Input from "../components/_core/Input";


const HomePage = () => {
    const navigate = useNavigate();
    const {user, authLoaded, login} = useAuthContext();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    async function handleLogin(e) {
        e.preventDefault();

        try {
            await login(username, password);
        } catch (error) {
            setErrorMsg(error.message);
        }
    }

    useEffect(() => {
        if (user && navigate) {
            navigate("/datasets");
        }
    }, [user, navigate])

    return (
        <div className="flex flex-col items-center gap-4 -mt-16">

            {!user ? <>
                <div>
                    <Logo className="w-64 mb-2 "/>
                    <h2 className="text-2xl text-slate-500">IKE Insight Ground Truth <strong
                        className="text-lg">V2</strong></h2>
                </div>
                <div className="px-12 pb-12 pt-8 bg-white">
                    <h2 className="text-3xl mb-4">Log in</h2>
                    <form onSubmit={handleLogin} className="flex flex-col w-80">

                        <Input name="username" className="mb-4 p-1"
                               label="Username"
                               required={true}
                               onChange={(e) => setUsername(e.target.value)}/>
                        <Input type="password" name="password" className="mb-4 p-1" required={true}
                               label="Password"
                               onChange={(e) => setPassword(e.target.value)}/>
                        <Button type="submit" disabled={!username || (!password || (password && password.length < 4))}
                                extraClasses="w-32">Log in</Button>
                    </form>
                </div>
                <p className="text-sm text-gray-400">&copy; 2022 ikeGPS Ltd. All Rights Reserved.</p>
            </> : <p className="text-lg text-gray-500">Loading...</p>}

        </div>
    )
}

export default HomePage;