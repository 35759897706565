import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../services/auth.service";
import Button from "../components/_core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDatabase} from "@fortawesome/free-solid-svg-icons";
import {useDataContext} from "../services/data.service";
import {data} from "autoprefixer";


const DatasetsPage = () => {
    const [selectedDatasets, setSelectedDatasets] = useState([]);
    const { datasets } = useDataContext();
    const loading = !datasets;

    const handleDatasetClicked = datasetId => {
        if (selectedDatasets.includes(datasetId)) {
            setSelectedDatasets(selectedDatasets.filter(x => x !== datasetId));
        } else {
            setSelectedDatasets([...selectedDatasets, datasetId]);
        }
    }

    useEffect(() => {
        if (!datasets) return;

        document.title = `GT2 - Datasets`
    }, [datasets])

    return (
        <div className="w-full h-full flex flex-col">
            <div className="flex justify-between items-end bg-slate-100 py-6 px-8">
                <h2 className="text-4xl font-bold">
                    <FontAwesomeIcon icon={faDatabase} className="mr-2" />
                    Datasets
                </h2>

                <div className="flex gap-4 items-center">
                    { !loading ? <p className="text-gray-500 text-sm">{selectedDatasets.length} dataset(s) selected.</p> : null }
                    <Button disabled={!datasets || datasets.length === 0 || selectedDatasets.length === 0}
                            as={"Link"} to={`/annotator?datasets=${selectedDatasets.join()}`}
                    >Open Selected Dataset(s)</Button>
                <Button style="muted" disabled={!datasets || datasets.length === 0 } as={"Link"} to={"/annotator?datasets=all"}>Open All Datasets</Button>
                </div>
            </div>
            <div className="flex-grow overflow-y-auto px-8 pt-4 pb-8" id="datasets">
                <div className="flex flex-col gap-4">
                    <div className="flex items-center text-slate-500 text-xl tracking-wider font-bold justify-between mx-4 sticky top-0">
                        <h2 className="w-16 invisible"><span className="sr-only">Selected</span></h2>
                        <h2 className="w-48">ID</h2>
                        <h2 className="w-72">Name</h2>
                        <h2 className="w-32">Images</h2>
                        <h2 className="w-32">Annotations</h2>
                        <h2 className="w-72 invisible"><span className="sr-only">Actions</span>
                        </h2>
                    </div>
                    {datasets ? datasets.map(dataset => (
                        <div key={dataset.id} className={`flex items-center justify-between rounded hover:ring-2 hover:bg-white hover:ring-blue-600 px-4 py-2 hover:cursor-pointer ` + (selectedDatasets.includes(dataset.id) ? "bg-slate-50 ring-1 ring-sky-400" : "bg-slate-100")} onClick={() => handleDatasetClicked(dataset.id)}>
                            <div className="w-16"><input type="checkbox" className="cursor-pointer pointer-events-none" checked={selectedDatasets.includes(dataset.id)} readOnly/></div>
                            <div className="w-48">{dataset.id}</div>
                            <div className="w-72">{dataset.name}</div>
                            <div className="w-32">{dataset.images}</div>
                            <div className="w-32">{dataset.annotations}</div>
                            <div className="w-72 flex items-center justify-end">
                                <Button as="Link" to={`/annotator?datasets=${dataset.id}`} size={"small"}
                                 style={"secondary"}
                                >
                                    Open Dataset
                                </Button>
                            </div>
                        </div>
                    )) : null}
                </div>
            </div>
        </div>
    )
}

export default DatasetsPage;