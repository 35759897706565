// fill = "currentColor"

const Logo = ({className = null}) => (
    <svg width="100%" height="100%" viewBox="0 0 139 74" version="1.1" xmlns="http://www.w3.org/2000/svg"
         className={className}>
        <g transform="matrix(1,0,0,1,-1023.26,-1331.89)">
            <path fill="currentColor" d="M1047.43,1354.69C1039.68,1354.69 1033.38,1361 1033.38,1368.75C1033.38,1376.5 1039.68,1382.8 1047.43,1382.8C1055.18,1382.8 1061.49,1376.5 1061.49,1368.75C1061.49,1361 1055.18,1354.69 1047.43,1354.69ZM1047.36,1392.91C1034.07,1392.87 1023.26,1382.03 1023.26,1368.75C1023.26,1355.42 1034.1,1344.58 1047.43,1344.58C1060.76,1344.58 1071.6,1355.42 1071.6,1368.75C1071.6,1385.92 1060.86,1399.94 1047.36,1405.86L1047.36,1392.91Z"/>
        </g>
        <g transform="matrix(1,0,0,1,-1946.26,-1331.89)">
            <path fill="currentColor" d="M2022.08,1392.92C2008.75,1392.92 1997.91,1382.08 1997.91,1368.75L1997.91,1331.89L2020.82,1354.88L2008.03,1354.88L2008.03,1368.35C2008.02,1368.49 2008.02,1368.62 2008.02,1368.75C2008.02,1376.5 2014.32,1382.81 2022.08,1382.81C2028.25,1382.81 2033.5,1378.81 2035.39,1373.27L2045.82,1373.27C2043.7,1384.45 2033.86,1392.92 2022.08,1392.92Z" />
        </g>
        <g transform="matrix(1,0,0,1,-597.059,-1447.74)">
            <path fill="currentColor" d="M735.853,1482.17C735.853,1470.18 726.099,1460.43 714.109,1460.43L709.256,1460.43C697.991,1460.43 688.7,1469.04 687.619,1480.03L697.829,1480.03C698.839,1474.64 703.577,1470.54 709.256,1470.54L714.109,1470.54C720.521,1470.54 725.738,1475.76 725.738,1482.17C725.738,1486.61 723.403,1490.39 719.584,1492.43C711.085,1496.97 689.018,1508.77 689.018,1508.77L735.853,1508.77L726.591,1499.96C732.186,1496.02 735.853,1489.52 735.853,1482.17Z"/>
        </g>
        <g transform="matrix(1,0,0,1,-1023.26,-1331.89)">
            <path fill="rgb(0,170,245)" d="M1047.43,1362.79C1044.15,1362.79 1041.48,1365.46 1041.48,1368.74C1041.48,1372.02 1044.15,1374.7 1047.43,1374.7C1050.72,1374.7 1053.39,1372.02 1053.39,1368.74C1053.39,1365.46 1050.72,1362.79 1047.43,1362.79Z" />
        </g>
        <g transform="matrix(1,0,0,1,-1946.26,-1331.89)">
            <path fill="rgb(170,50,255)" d="M2022.08,1362.73C2018.79,1362.73 2016.12,1365.4 2016.12,1368.68C2016.12,1371.97 2018.79,1374.64 2022.08,1374.64C2025.36,1374.64 2028.03,1371.97 2028.03,1368.68C2028.03,1365.4 2025.36,1362.73 2022.08,1362.73Z" />
        </g>
        <g transform="matrix(1,0,0,1,-597.059,-1447.74)">
            <path fill="rgb(235,20,0)" d="M711.736,1476.76C708.452,1476.76 705.78,1479.43 705.78,1482.71C705.78,1486 708.452,1488.67 711.736,1488.67C715.02,1488.67 717.691,1486 717.691,1482.71C717.691,1479.43 715.02,1476.76 711.736,1476.76Z" />
        </g>
        {/*<g transform="matrix(1,0,0,1,-1023.26,-1331.89)">*/}
        {/*    <path fill="rgb(0,200,255)"*/}
        {/*          d="M1047.43,1362.79C1044.15,1362.79 1041.48,1365.46 1041.48,1368.74C1041.48,1372.02 1044.15,1374.7 1047.43,1374.7C1050.72,1374.7 1053.39,1372.02 1053.39,1368.74C1053.39,1365.46 1050.72,1362.79 1047.43,1362.79Z"/>*/}
        {/*</g>*/}
        {/*<g transform="matrix(1,0,0,1,-1946.26,-1331.89)">*/}
        {/*    <path fill="rgb(100,0,255)"*/}
        {/*          d="M2022.08,1362.73C2018.79,1362.73 2016.12,1365.4 2016.12,1368.68C2016.12,1371.97 2018.79,1374.64 2022.08,1374.64C2025.36,1374.64 2028.03,1371.97 2028.03,1368.68C2028.03,1365.4 2025.36,1362.73 2022.08,1362.73Z"/>*/}
        {/*</g>*/}
        {/*<g transform="matrix(1,0,0,1,-597.059,-1447.74)">*/}
        {/*    <path fill="rgb(225,0,0)"*/}
        {/*          d="M711.736,1476.76C708.452,1476.76 705.78,1479.43 705.78,1482.71C705.78,1486 708.452,1488.67 711.736,1488.67C715.02,1488.67 717.691,1486 717.691,1482.71C717.691,1479.43 715.02,1476.76 711.736,1476.76Z"/>*/}
        {/*</g>*/}
    </svg>
)

export default Logo;