import React from "react";
import {useDataContext} from "../../../services/data.service";

const LaneHeader = ({
                        onDoubleClick,
                        title,
                        id,
                        color,
                        titleStyle,
                        laneDraggable
                    }) => {
    const isPole = title.toLowerCase().includes("pole");

    const {
        setHoveredRegion
    } = useDataContext();

    return (
        <div onDoubleClick={onDoubleClick}
             id={id}
             onMouseEnter={() => isPole && setHoveredRegion(id)}
             onMouseLeave={() => isPole && setHoveredRegion(null)}
             className={`font-brand font-bold text-xl -mx-2 pl-2 py-1 rounded ${isPole ? "text-slate-600 bg-white bg-rounded hover:text-blue-800 hover:cursor-pointer hover:bg-blue-100" : "text-slate-700"}`}>
            <div draggable={laneDraggable} style={titleStyle}>
                { isPole ? <div className="w-2 h-2 rounded-full inline-block mr-2 mb-1" style={{backgroundColor: color}}/> : null }
                {title}
            </div>
        </div>
    )
}

export default LaneHeader;